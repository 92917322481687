<template>
  <v-app-bar
      app
      color="primary"
      dark
      dense
  >
    <div class="d-flex align-center">
      <router-link class="mx-3 text-decoration-none white--text" to="/dashboard"><strong>{{ company.name }}</strong></router-link>
    </div>
    <v-spacer></v-spacer>
    <router-link class="text-decoration-none white--text mr-4" to="/dashboard">Dashboard</router-link>
    <router-link class="text-decoration-none white--text mr-4" to="/settings">Settings</router-link>
    <login-button></login-button>
    <logout-button></logout-button>
    <loader></loader>
  </v-app-bar>
</template>

<script>
import Loader from "../common/Loader";
import LogoutButton from "../common/LogoutButton";
import LoginButton from "../common/LoginButton";
export default {
  name: 'HealthPatientHeader',
  components: {LoginButton, LogoutButton, Loader },
  props: {
    company: {
      type: Object,
      default: () => {
        return {
          company: 'Doctor Portal',
          contact: 'info@dayvue.health'
        }
      }
    },
  }
};
</script>
