<template>
  <v-row
      v-if="show"
  >
    <v-col>
      <v-alert
          class="ma-0"
          border="left"
          :color="color"
          dark
      >
        <p
            v-for="(alert, i) in alerts"
            :key="i"
            class="ma-0">{{ alert.message }}</p>
      </v-alert>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: 'HealthPatientAlert',
  computed: {
    ...mapGetters(['getAlerts']),
    show () {
      return this.getAlerts.some((alert) => {
        if (alert) {
          if (alert.active) {
            return alert.active
          } else {
            return false
          }
        } else {
          return false
        }
      })
    },
    alerts () {
      return this.getAlerts.reduce((accumulator, alert) => {
        if (alert) {
          if (alert.active) {
            accumulator.push(alert)
          }
        }

        return accumulator
      }, [])
    },
    color () {
      if (this.alerts.length > 0 ) {
        if (this.alerts[0].color) {
          return this.alerts[0].color
        } else {
          return ''
        }
      } else {
        return ''
      }
    }
  }
}
</script>
