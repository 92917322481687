import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';

const API_URL = 'https://service.dayvue.health'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        loader: false,
        alerts: [],
        data: {},
        overlay: {
            state: false,
            to: ''
        },
        metaData: null,
        metrics: []
    },
    mutations: {
        mutateMetaData (state, data) {
            state.metaData = data
        },
        mutateMetrics (state, data) {
            state.metrics = data
        },
        mutateData (state, data) {
            state.data = data
        },
        mutateLoader (state, loader) {
            state.loader = loader
        },
        mutateOverlay (state, overlay) {
            if (overlay) {
                state.overlay = overlay
            } else {
                state.overlay = {
                    state: false,
                    to: ''
                }
            }
        },
        mutateAlerts (state, alert) {
            if (alert) {
                state.alerts.map((existingAlert) => {
                    if (existingAlert.message === alert.message) {
                        existingAlert.active = false
                    }

                    return existingAlert
                })
                state.alerts.push(alert)
            } else {
                state.alerts = []
            }
        }
    },
    getters: {
        getMetrics (state) {
            return state.metrics
        },
        getMetaData (state) {
            return state.metaData
        },
        getData (state) {
            return state.data
        },
        getLoader (state) {
            return state.loader
        },
        getOverlay (state) {
            return state.overlay
        },
        getAlerts (state) {
            return state.alerts
        }
    },
    actions: {
        setMetrics({ commit }, data) {
            commit('mutateMetrics', data)
        },
        setMetaData({ commit }, data) {
            commit('mutateMetaData', data)
        },
        setData({ commit }, data) {
            commit('mutateData', data)
        },
        setAlerts ({ commit }, data) {
            commit('mutateAlerts', data)
        },
        setOverlay({ commit }, data) {
            commit('mutateOverlay', data)
        },
        setLoader ({ commit }, data) {
            commit('mutateLoader', data)
        },
        fetchAllPatients ({ commit }, data) {
            return new Promise((resolve, reject) => {
                commit('mutateAlerts', null)
                commit('mutateLoader', true)

                if (data.accessToken) {
                    axios.get(
                        `${API_URL}/doctor/fetch-doctor-patients`,
                        {
                            headers: {
                                authorization: `Bearer ${data.accessToken}`,
                            }
                        }
                    )
                        .then((results) => {
                            if (results.data) {
                                commit('mutateLoader', false)
                                resolve(results.data)
                            } else {
                                commit('mutateLoader', false)
                                resolve([])
                            }
                        })
                        .catch((error) => {
                            commit('mutateAlerts', {
                                active: true,
                                message: 'Fetching data failed.',
                                color: 'error'
                            })
                            commit('mutateLoader', false)
                            reject(error)
                        })
                } else {
                    commit('mutateAlerts', {
                        active: true,
                        message: 'Fetching data failed.',
                        color: 'error'
                    })
                    commit('mutateLoader', false)
                    reject()
                }
            })
        },
        insertPatient ({ commit }, data) {
            return new Promise((resolve, reject) => {
                commit('mutateAlerts', null)
                commit('mutateLoader', true)

                if (data.accessToken) {
                    axios.post(
                        `${API_URL}/doctor/insert-patient`,
                        data,
                        {
                            headers: {
                                authorization: `Bearer ${data.accessToken}`,
                            }
                        }
                    )
                        .then((result) => {
                            commit('mutateLoader', false)
                            if (result) {
                                if (result.data) {
                                    if (result.data[0]) {
                                        resolve(result.data[0])
                                    } else {
                                        resolve()
                                    }
                                } else {
                                    resolve()
                                }
                            } else {
                                resolve()
                            }
                        })
                        .catch((error) => {
                            commit('mutateAlerts', {
                                active: true,
                                message: 'Your update was not submitted, it looks like we\'re experiencing technical issues.',
                                color: 'error'
                            })
                            commit('mutateLoader', false)
                            reject(error)
                        })
                } else {
                    commit('mutateAlerts', {
                        active: true,
                        message: 'Your update was not submitted, it looks like we\'re experiencing technical issues.',
                        color: 'error'
                    })
                    commit('mutateLoader', false)
                    reject()
                }
            })
        },
        deletePatientById ({ commit }, data) {
            return new Promise((resolve, reject) => {
                commit('mutateAlerts', null)
                commit('mutateLoader', true)

                if (data.accessToken) {
                    axios.post(
                        `${API_URL}/doctor/delete-patient-by-id`,
                        data,
                        {
                            headers: {
                                Authorization: `Bearer ${data.accessToken}`,
                            }
                        }
                    )
                        .then(() => {
                            commit('mutateLoader', false)
                            resolve()
                        })
                        .catch((error) => {
                            commit('mutateAlerts', {
                                active: true,
                                message: 'Your update was not submitted, it looks like we\'re experiencing technical issues.',
                                color: 'error'
                            })
                            commit('mutateLoader', false)
                            reject(error)
                        })
                } else {
                    commit('mutateAlerts', {
                        active: true,
                        message: 'Your update was not submitted, it looks like we\'re experiencing technical issues.',
                        color: 'error'
                    })
                    commit('mutateLoader', false)
                    reject()
                }
            })
        },
        updatePatientById ({ commit }, data) {
            return new Promise((resolve, reject) => {
                commit('mutateAlerts', null)
                commit('mutateLoader', true)

                if (data.accessToken) {
                    axios.post(
                        `${API_URL}/doctor/update-patient-by-id`,
                        data,
                        {
                            headers: {
                                Authorization: `Bearer ${data.accessToken}`,
                            }
                        }
                    )
                        .then((result) => {
                            if (result) {
                                if (result.data) {
                                    if (result.data[0]) {
                                        resolve(result.data[0])
                                    } else {
                                        resolve()
                                    }
                                } else {
                                    resolve()
                                }
                            } else {
                                resolve()
                            }
                            commit('mutateLoader', false)
                        })
                        .catch((error) => {
                            commit('mutateAlerts', {
                                active: true,
                                message: 'Your update was not submitted, it looks like we\'re experiencing technical issues.',
                                color: 'error'
                            })
                            commit('mutateLoader', false)
                            reject(error)
                        })
                } else {
                    commit('mutateAlerts', {
                        active: true,
                        message: 'Your update was not submitted, it looks like we\'re experiencing technical issues.',
                        color: 'error'
                    })
                    commit('mutateLoader', false)
                    reject()
                }
            })
        },
        updatePatientStatusById ({ commit }, data) {
            return new Promise((resolve, reject) => {
                commit('mutateAlerts', null)
                commit('mutateLoader', true)

                if (data.accessToken) {
                    axios.post(
                        `${API_URL}/doctor/update-patient-status-by-id`,
                        data,
                        {
                            headers: {
                                authorization: `Bearer ${data.accessToken}`,
                            }
                        }
                    )
                        .then((result) => {
                            commit('mutateLoader', false)
                            if (result) {
                                if (result.data) {
                                    if (result.data[0]) {
                                        resolve(result.data[0])
                                    } else {
                                        resolve()
                                    }
                                } else {
                                    resolve()
                                }
                            } else {
                                resolve()
                            }
                        })
                        .catch((error) => {
                            commit('mutateAlerts', {
                                active: true,
                                message: 'Your update was not submitted, it looks like we\'re experiencing technical issues.',
                                color: 'error'
                            })
                            commit('mutateLoader', false)
                            reject(error)
                        })
                } else {
                    commit('mutateAlerts', {
                        active: true,
                        message: 'Your update was not submitted, it looks like we\'re experiencing technical issues.',
                        color: 'error'
                    })
                    commit('mutateLoader', false)
                    reject()
                }
            })
        },
        updateNotificationStatusById ({ commit }, data) {
            return new Promise((resolve, reject) => {
                commit('mutateAlerts', null)
                commit('mutateLoader', true)

                if (data.accessToken) {
                    axios.post(
                        `${API_URL}/doctor/update-notification-status-by-id`,
                        data,
                        {
                            headers: {
                                authorization: `Bearer ${data.accessToken}`,
                            }
                        }
                    )
                        .then((result) => {
                            commit('mutateLoader', false)
                            if (result) {
                                if (result.data) {
                                    if (result.data[0]) {
                                        resolve(result.data[0])
                                    } else {
                                        resolve()
                                    }
                                } else {
                                    resolve()
                                }
                            } else {
                                resolve()
                            }
                        })
                        .catch((error) => {
                            commit('mutateAlerts', {
                                active: true,
                                message: 'Your update was not submitted, it looks like we\'re experiencing technical issues.',
                                color: 'error'
                            })
                            commit('mutateLoader', false)
                            reject(error)
                        })
                } else {
                    commit('mutateAlerts', {
                        active: true,
                        message: 'Your update was not submitted, it looks like we\'re experiencing technical issues.',
                        color: 'error'
                    })
                    commit('mutateLoader', false)
                    reject()
                }
            })
        },
        fetchMessageTypes ({ commit }, data) {
            return new Promise((resolve, reject) => {
                commit('mutateAlerts', null)
                commit('mutateLoader', true)

                if (data.accessToken) {
                    axios.get(
                        `${API_URL}/doctor/fetch-message-types`,
                        {
                            headers: {
                                authorization: `Bearer ${data.accessToken}`,
                            }
                        }
                    )
                        .then((result) => {
                            commit('mutateLoader', false)
                            if (result) {
                                if (result.data) {
                                    if (Array.isArray(result.data)) {
                                        resolve(result.data)
                                    } else {
                                        resolve([])
                                    }
                                } else {
                                    resolve([])
                                }
                            } else {
                                resolve([])
                            }
                        })
                        .catch((error) => {
                            commit('mutateAlerts', {
                                active: true,
                                message: 'Message types were not fetched, it looks like we\'re experiencing technical issues.',
                                color: 'error'
                            })
                            commit('mutateLoader', false)
                            reject(error)
                        })
                } else {
                    commit('mutateAlerts', {
                        active: true,
                        message: 'Message types were not fetched, it looks like we\'re experiencing technical issues.',
                        color: 'error'
                    })
                    commit('mutateLoader', false)
                    reject()
                }
            })
        },
        manuallySendMessage ({ commit }, data) {
            return new Promise((resolve, reject) => {
                commit('mutateAlerts', null)
                commit('mutateLoader', true)

                if (data.accessToken && data.message) {
                    axios.post(
                        `${API_URL}/doctor/manually-send-message`,
                        data,
                        {
                            headers: {
                                authorization: `Bearer ${data.accessToken}`,
                            }
                        }
                    )
                        .then(() => {
                            commit('mutateLoader', false)
                            resolve()
                        })
                        .catch((error) => {
                            commit('mutateAlerts', {
                                active: true,
                                message: 'Message was not sent, it looks like we\'re experiencing technical issues.',
                                color: 'error'
                            })
                            commit('mutateLoader', false)
                            reject(error)
                        })
                } else {
                    commit('mutateAlerts', {
                        active: true,
                        message: 'Message was not sent, it looks like we\'re experiencing technical issues.',
                        color: 'error'
                    })
                    commit('mutateLoader', false)
                    reject()
                }
            })
        },
        fetchPatientData ({ commit }, data) {
            return new Promise((resolve, reject) => {
                commit('mutateAlerts', null)
                commit('mutateLoader', true)

                if (data.accessToken && data.index) {
                    axios.get(
                        `${API_URL}/doctor/fetch-patient-data?index=${data.index}`,
                        {
                            headers: {
                                authorization: `Bearer ${data.accessToken}`,
                            }
                        }
                    )
                        .then((results) => {
                            commit('mutateLoader', false)

                            if (results) {
                                if (results.data) {
                                    commit('mutateData', results.data)
                                    resolve(results.data)

                                } else {
                                    resolve({})
                                }
                            } else {
                                resolve({})
                            }
                        })
                        .catch((error) => {
                            commit('mutateAlerts', {
                                active: true,
                                message: 'Fetching data failed.',
                                color: 'error'
                            })
                            commit('mutateLoader', false)
                            reject(error)
                        })
                } else {
                    commit('mutateAlerts', {
                        active: true,
                        message: 'Fetching data failed.',
                        color: 'error'
                    })
                    commit('mutateLoader', false)
                    reject({})
                }
            })
        },
        updatePatientMetrics ({ commit }, data) {
            return new Promise((resolve, reject) => {
                commit('mutateAlerts', null)
                commit('mutateLoader', true)

                if (data.accessToken) {
                    axios.post(
                        `${API_URL}/doctor/update-patient-metrics-by-id`,
                        data,
                        {
                            headers: {
                                authorization: `Bearer ${data.accessToken}`,
                            }
                        }
                    )
                        .then((result) => {
                            commit('mutateLoader', false)
                            if (result) {
                                if (result.data) {
                                    if (result.data[0]) {
                                        resolve(result.data[0])
                                    } else {
                                        resolve()
                                    }
                                } else {
                                    resolve()
                                }
                            } else {
                                resolve()
                            }
                        })
                        .catch((error) => {
                            commit('mutateAlerts', {
                                active: true,
                                message: 'Your update was not submitted, it looks like we\'re experiencing technical issues.',
                                color: 'error'
                            })
                            commit('mutateLoader', false)
                            reject(error)
                        })
                } else {
                    commit('mutateAlerts', {
                        active: true,
                        message: 'Your update was not submitted, it looks like we\'re experiencing technical issues.',
                        color: 'error'
                    })
                    commit('mutateLoader', false)
                    reject()
                }
            })
        },
        fetchUserSettings ({ commit }, data) {
            return new Promise((resolve, reject) => {
                commit('mutateAlerts', null)

                if (this.state.metaData) {
                    resolve(this.state.metaData)
                } else {
                    axios.get(
                        `${API_URL}/doctor/user-settings`,
                        {
                            headers: {
                                authorization: `Bearer ${data.authToken}`,
                            }
                        }
                    )
                        .then((results) => {
                            if (results) {
                                if (results.data) {
                                    commit('mutateMetaData', results.data)
                                    resolve(results.data)
                                } else {
                                    commit('mutateAlerts', {
                                        active: true,
                                        message: 'Projects were not fetched.',
                                        color: 'secondary'
                                    })
                                    resolve({})
                                }
                            } else {
                                commit('mutateAlerts', {
                                    active: true,
                                    message: 'User settings were not fetched.',
                                    color: 'error'
                                })
                                resolve({})
                            }
                        })
                        .catch((error) => {
                            commit('mutateAlerts', {
                                active: true,
                                message: 'Fetching user settings failed.',
                                color: 'error'
                            })
                            reject(error)
                        })
                }
            })
        },
        updateUserSettings ({ commit }, data) {
            return new Promise((resolve, reject) => {
                commit('mutateAlerts', null)

                axios.put(
                    `${API_URL}/doctor//user-settings`,
                    data,
                    {
                        headers: {
                            authorization: `Bearer ${data.authToken}`,
                        }
                    }
                )
                    .then(() => {
                        commit('mutateMetaData', null)
                        resolve()
                    })
                    .catch((error) => {
                        commit('mutateAlerts', {
                            active: true,
                            message: 'Updating user settings failed.',
                            color: 'error'
                        })
                        reject(error)
                    })
            })
        },
        fetchMetrics ({ commit }, data) {
            return new Promise((resolve, reject) => {
                commit('mutateAlerts', null)

                if (this.state.metrics.length > 0) {
                    resolve(this.state.metrics)
                } else {
                    axios.get(
                        `${API_URL}/doctor/metrics`,
                        {
                            headers: {
                                authorization: `Bearer ${data.authToken}`,
                            }
                        }
                    )
                        .then((results) => {
                            if (results) {
                                if (results.data) {
                                    commit('mutateMetrics', results.data)
                                    resolve(results.data)
                                } else {
                                    commit('mutateAlerts', {
                                        active: true,
                                        message: 'Metrics were not fetched.',
                                        color: 'secondary'
                                    })
                                    resolve({})
                                }
                            } else {
                                commit('mutateAlerts', {
                                    active: true,
                                    message: 'Metrics were not fetched.',
                                    color: 'error'
                                })
                                resolve({})
                            }
                        })
                        .catch((error) => {
                            commit('mutateAlerts', {
                                active: true,
                                message: 'Fetching metrics failed.',
                                color: 'error'
                            })
                            reject(error)
                        })
                }
            })
        },
        updateMetrics ({ commit }, data) {
            return new Promise((resolve, reject) => {
                commit('mutateAlerts', null)

                axios.put(
                    `${API_URL}/doctor/metrics`,
                    data,
                    {
                        headers: {
                            authorization: `Bearer ${data.authToken}`,
                        }
                    }
                )
                    .then(() => {
                        commit('mutateMetaData', null)
                        resolve()
                    })
                    .catch((error) => {
                        commit('mutateAlerts', {
                            active: true,
                            message: 'Updating metrics failed.',
                            color: 'error'
                        })
                        reject(error)
                    })
            })
        },
    }
})
