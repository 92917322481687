import Vue from 'vue'
import Router from 'vue-router'
import { authenticationGuard, authenticationRedirect } from '@/auth/authenticationGuard';

Vue.use(Router)

const router = new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: [
        {
            path: '/dashboard',
            name: 'Dashboard',
            component: () => import('@/views/Dashboard.vue'),
            beforeEnter: authenticationGuard
        },
        {
            path: '/settings',
            name: 'Settings',
            component: () => import('@/views/Settings.vue'),
            beforeEnter: authenticationGuard
        },
        {
            path: '/',
            name: 'Login',
            component: () => import('@/views/Login.vue'),
            beforeEnter: authenticationRedirect
        },
    ],
})

export default router
